import EventComponent from "@/views/sysviews/config/business/eventCompInf";
import Event from "@/views/sysviews/config/business/event";
//该模块是处理采用列表引擎作为列表页面的时候：1、进入列表引擎，需要添加请求地址和弹出框（新增/编辑）包含的组件的类别
export default class EventListHelper {
    //【进入列表引擎处理】需要为列表引擎赋值请求后台的地址
    static enterListEngine(event:any,modelMethod:string,compParams:any={}):void{
        let engineInst=event.detail.engineInst;
        compParams=Object.assign({modelType:'card',isEnginePage:true,modelPath:modelMethod.substr(0,modelMethod.indexOf('/pageData'))},compParams)
        engineInst.listParam.modelType=compParams.modelType;
        engineInst.listParam.modelMethod=modelMethod;
        engineInst.listParam.compParams=compParams;//如果采用引擎作为页面，那么该对象是传给引擎渲染页面用的

        if(engineInst.otherParams.componentLoadFlag){//已经打开过一个ListEngine了
            //当后台直接请求的ListEngine页面时，如果有多个页面都指向ListEngine，后打开的页面会保留前一个打开页面的ListEngine设置，
            //所以进入ListEngine的时候，需要重置一些变量
            engineInst.getTbInst().params.modelMethod=engineInst.listParam.modelMethod;
            engineInst.getTbInst().params.title=engineInst.listParam.title;
            engineInst.fullscreenLoading=true;
            engineInst.queryCondition=['queryCondition'];
            engineInst.listParam.queryParam={};
            engineInst.doOnMounted();//由于是同一个组件，只有再重新加载一遍数据
        }
    }
    //【处理列表pageInfo】处理后台返回的列表pageInfo信息，这里改变了之后，在事件触发的地方也会跟着改变
    static dealListPageInfo(event:any):void{
        let modelName=event.type.split('_')[0].substr(1);//event.type就是事件名
        //处理查询条件的组件
        Event.bindCompPropEvent(event.detail.pageInfo.conditions,modelName,'listCondition');
        if((EventComponent as any)[modelName+'_dealColumns'])(EventComponent as any)[modelName+'_dealColumns'](event.detail.pageInfo.columns);
        // switch (modelName) {//可以根据不同的模块额外处理
        //     case 'complexCard':
        //         break;
        // }
    }
}